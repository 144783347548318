import React, {Component} from 'react'

const allImgInfo = [
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/goodlord.png", link: "https://www.goodlord.co/", name: "Goodlord", desc: "Software making renting simple"},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/pandazzz.png", link: "https://pandazzz.com/", name: "Pandazzz", desc: "Affordable comfortable mattresses"},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/consumertec.png", link: "https://www.consumertec.com/consumertec_br/index_br.html", name: "Consumertec", desc: "R&D Company in Consumer's Benefit Perceptions", width: 1000, height: 454},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/silca.png", link: "https://silca.cc/", name: "Silca", desc: "Premium Bicycle Parts", width: 410, height: 92},
    {src: "https://woyera.com/assets/images/image17.png?v=a33b003a", link: "https://powr2.com/", name: "Powr2", desc: "Software for design and delivery"},
]


class CustomerCarousel extends Component {
    render() {
        const height = 50;
        const isMobile = this.props.isMobile;

        const logos = allImgInfo.map((x, idx) =>
            {
                return (
                    <div key={idx.toString()} className={isMobile ? "col-sm-2" : ""} style={isMobile ? {marginBottom: "16px"} : null}>
                        <a href={x.link} target="_blank" rel="noopener noreferrer" className="nostyle" style={{textAlign: 'center'}}>
                            <div style={{margin: "0 auto",
                                paddingTop: x.name!=="Pandazzz" ? "10px" : null
                            }}
                            >
                                <img src={x.src}
                                     style={{width: isMobile ? "30%" : "80%"}}
                                     alt={x.name + " Logo"}/>
                            </div>
                        </a>
                    </div>
                )
            }
        )
        return (
            <div>
                <div style={isMobile ? null : {display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"}} className={isMobile ? "row" : ""}>
                    {logos}
                </div>
                { isMobile ? <div className="col-sm-2" /> : null }
            </div>
        )
    }
}

export default CustomerCarousel