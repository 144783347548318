import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";

class LandingStep extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-6">
                        <div style={{ display: "grid", gridTemplateColumns: 'auto 1fr', columnGap: this.props.isMobile ? "8px" : "16px" }}>
                            <div>
                                <span className="circleBg" style={{ backgroundColor: styles.red, color: "white" }}>
                                    {this.props.num}
                                </span>
                            </div>
                            <div style={{ marginTop: this.props.isMobile ? "-8px" : "2px", textAlign: "left" }}>
                                <span style={{color: styles.mainText, fontSize: this.props.isMobile ? "20px" : "24px", fontWeight: "700"}}>
                                    {this.props.label}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div style={{ marginTop: this.props.isMobile ? "18px": "0"}}>
                            {this.props.body}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(LandingStep)