import React, {Component} from 'react'

class SupportLinks extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Support</div>
                <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                    <div style={{color: "#9696A0", fontSize: "12px"}}>Hours</div>
                    <p style={{color: "#161E16", fontSize: "14px"}}>Monday-Friday 7AM to 7PM GMT</p>
                </div>
                <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                    <div style={{color: "#9696A0", fontSize: "12px"}}>Tech Support</div>
                    <a className="footerItem" href="mailto:info@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>info@lovespreadsheets.com</a>
                </div>
                <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                    <div style={{color: "#9696A0", fontSize: "12px"}}>Sales & Billing</div>
                    <a className="footerItem" href="mailto:sales@apispreadsheets.com" style={{color: "#161E16", fontSize: "14px"}}>sales@lovespreadsheets.com</a>
                </div>
                <div style={{fontFamily: "Lato", fontWeight: "400", marginBottom: "12px"}}>
                    <div style={{color: "#9696A0", fontSize: "12px"}}>Schedule Call</div>
                    <a className="footerItem" href="https://calendly.com/love-spreadsheets/25min" target="_blank" rel="noopener noreferrer" style={{color: "#161E16", fontSize: "14px"}}>Click here to schedule call</a>
                </div>
            </div>
        )
    }
}

export default SupportLinks