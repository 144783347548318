import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../../Constants/styles";
import DatabaseNoCode from "./DatabaseNoCode";
import APINoCode from "./APINoCode"
import WebsiteNoCode from "./WebsiteNoCode"

class NoCodeTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // could be "code" or "prompt"
            mode: "database"
        }
    }

    getDivStyle = (selectionName) => {
        const headingStyle = { textAlign: "center",
            padding: "8px 16px",
            cursor: "pointer",
            // borderBottom: "1px solid #F0F0F0",
            fontSize: "12px",
            borderRadius: selectionName === "database" ? "4px 0 0 0" : selectionName === 'website' ? "0 4px 0 0" : "0",
            fontWeight: "700" }

        let selectedStyle = {...headingStyle}

        if (selectionName === this.state.mode){
            selectedStyle['backgroundColor'] = styles.blue
            selectedStyle['color'] = "white"
            selectedStyle['borderRight'] = "none"
        } else {
            selectedStyle['borderRight'] = "1px solid #F0F0F0"
            selectedStyle['borderTop'] = "1px solid #F0F0F0"
            selectedStyle['borderLeft'] = "1px solid #F0F0F0"
            selectedStyle['backgroundColor'] = "white"
        }

        return selectedStyle
    }

    getIconStyle = (selectionName) => {
        const iconStyle = { color: "#000000", fontSize: "14px"}

        if (selectionName === this.state.mode){
            let selectedIconStyle = {...iconStyle}

            selectedIconStyle['color'] = 'white'

            return selectedIconStyle
        } else {
            return {...iconStyle}
        }
    }

    getNoCodeBody = () => {
        const labelHeaderStyle = {fontFamily: "Lato", fontSize: "14px", fontWeight: "400", color: "#5E645E", marginBottom: "8px"}

        const bodyMap = {
            database: <DatabaseNoCode labelHeaderStyle={labelHeaderStyle}/>,
            api: <APINoCode labelHeaderStyle={labelHeaderStyle} isMobile={this.props.isMobile}/>,
            website: <WebsiteNoCode labelHeaderStyle={labelHeaderStyle} reportLandingIdx={0} isMobile={this.props.isMobile}/>
        }

        if (this.state.mode in bodyMap){
            return bodyMap[this.state.mode]
        }

        return null
    }

    render() {


        return (
            <div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr"}}>
                    <div style={this.getDivStyle("database")} onClick={e => this.setState({ mode: "database"})}>
                        <i className="fa-solid fa-database"></i> {this.props.isMobile ? <br/> : null}<span style={{ paddingLeft: this.props.isMobile ? "0" : "4px"}}>Database</span>
                    </div>
                    <div style={this.getDivStyle("api")} onClick={e => this.setState({ mode: "api"})}>
                        <i className="fa-solid fa-code"></i> {this.props.isMobile ? <br/> : null}<span style={{ paddingLeft: this.props.isMobile ? "0" : "4px"}}>API</span>
                    </div>
                    {/*<div style={this.getDivStyle("website")} onClick={e => this.setState({ mode: "website"})}>*/}
                        {/*<i className="fa-brands fa-internet-explorer"></i> {this.props.isMobile ? <br/> : null}<span style={{ paddingLeft: this.props.isMobile ? "0" : "4px"}}>Website</span>*/}
                    {/*</div>*/}
                </div>
                <div style={{ border: "1px solid #F0F0F0", padding: "24px"}}>
                    {this.getNoCodeBody()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(NoCodeTable)