import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import {getUTCStringFromOffset} from "../Constants/getUTCStringFromOffset";
import ChangeReportFrequency from "../Database/Reports/SetupTime/ChangeReportFrequency"
import TimeSelection from "../Database/Reports/SetupTime/TimeSelection"
import TimezoneDropdown from "../Database/Reports/SetupTime/TimezoneDropdown";
import WeeklyDaysSelection from "../Database/Reports/SetupTime/WeeklyDaysSelection";
import MonthlySelection from "../Database/Reports/SetupTime/MonthlySelection";
import YearlySelection from "../Database/Reports/SetupTime/YearlySelection";
import GoogleSheetsAddToSheet from "../Illustrations/GoogleSheetsAddToSheet.svg"
import GoogleSheetsCreateNewSheet from "../Illustrations/GoogleSheetsCreateNewSheet.svg"

class GetDataGoogleSheetsFrequency extends Component {
    constructor(props) {
        super(props);

        this.state = {
            report_frequency: 'monthly',
            report_info: {},
            report_times: [],
            send_email_report: false,
            send_google_sheets: true,
            email_report_info: {},
            google_sheets_info: {},
            interval: '15-minute',
            report_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }

    getUserReportFrequency = () => ("15-minutes")

    changeState = (newState) => this.setState(newState)

    getFrequencyBody = () => {
        const bodyMap = {
            daily: null,
            daily_weekday: null,
            weekly: <WeeklyDaysSelection
                report_info={this.state.report_info}
                changeState={this.changeState}
            />,
            monthly: <MonthlySelection
                userReportFrequency={this.getUserReportFrequency()}
                report_info={this.state.report_info}
                changeState={this.changeState}
            />,
            yearly: <YearlySelection
                userReportFrequency={this.getUserReportFrequency()}
                report_info={this.state.report_info}
                changeState={this.changeState}
            />
        }

        if (this.state.report_frequency in bodyMap){
            return bodyMap[this.state.report_frequency]
        }

        return null
    }

    render() {
        const subHeaderStyle = {fontSize: "16px", color: styles.subText, fontWeight: "700"}

        return (
            <div style={{ backgroundColor: "white", padding: "12px", border: '1px solid #F0F0F0', borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)" }}>
                <div style={ this.props.isMobile ? null : { display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "8px"}} className={this.props.isMobile ? "row" : ""}>
                    <div className={this.props.isMobile ? "col-sm-4" : ""}>
                        <div style={subHeaderStyle}>
                            Frequency
                        </div>
                        <div>
                            <ChangeReportFrequency
                                userReportFrequency={this.getUserReportFrequency()}
                                report_frequency={this.state.report_frequency}
                                changeState={this.changeState}
                            />
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className={this.props.isMobile ? "col-sm-4" : ""} style={this.props.isMobile ? {marginTop: "8px"} : null}>
                        <div style={subHeaderStyle}>
                            Time
                        </div>
                        <div>
                            <TimeSelection
                                userReportFrequency={this.getUserReportFrequency()}
                                report_times={this.state.report_times}
                                interval={this.state.interval}
                                changeState={this.changeState}
                            />
                        </div>
                    </div>
                    <div className={this.props.isMobile ? "col-sm-4" : ""} style={this.props.isMobile ? {marginTop: "8px"} : null}>
                        <div style={subHeaderStyle}>
                            Timezone
                        </div>
                        <div>
                            <TimezoneDropdown
                                report_timezone={this.state.report_timezone}
                                changeState={this.changeState}
                            />
                        </div>
                    </div>
                </div>
                <div style={this.props.isMobile ? {marginTop: "8px"} : null}>
                    {this.getFrequencyBody()}
                </div>
                <div style={{ marginTop: "24px"}}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div style={{ textAlign: "center"}}>
                                <img src={GoogleSheetsAddToSheet} style={{ maxWidth: '160px'}} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div style={{ textAlign: "center"}}>
                                <img src={GoogleSheetsCreateNewSheet} style={{ maxWidth: '160px'}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(GetDataGoogleSheetsFrequency)