import React, {Component} from 'react'
import PageBanner from "../PageBanner";
import Integration from "../../Illustrations/Integrate-01.svg"
import {faqTexts} from "../../FAQs/faqTexts";
import {integrateFAQs} from "./integrateFAQs";

import RequestDemoSegment from "../../Landing/RequestDemoSegment";
import IntegrateBody from "./IntegrateBody";

const allInputs = ["Show me the top performing sales people        ",
    "Find the top 10 most populated countries         ",
    "I want all records where James was an account executive         "]

class IntegrateIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animatedInput: "",
            inputIdx: 0
        }
    }

    componentDidMount(){
        this.interval = setInterval(this.updateInput, 30)
    }

    componentWillUnmount() {
        // Clear the interval right before component unmount
        clearInterval(this.interval);
    }

    updateInput = () => {
        const input = allInputs[this.state.inputIdx]

        if (this.state.animatedInput === input){
            this.setState({
                inputIdx: (this.state.inputIdx + 1) % 3,
                animatedInput: ""
            })
        } else {
            if (this.state.animatedInput === ""){
                this.setState({
                    animatedInput: input.substring(0, 1)
                })
            } else {
                this.setState({
                    animatedInput: input.substring(0, input.indexOf(this.state.animatedInput) + this.state.animatedInput.length + 1)
                })
            }
        }
    }

    render() {
        const title = 'Integrate Chat Bot with other Data Sources'
        const desc = "Ask questions in natural language, and get insights from your databases, CRM, SaaS and much more"
        const inputStyle = {fontSize: "20px", fontWeight: "700", width: '100%'}
        const ctaHeader = <span style={{ fontWeight: "700"}}>Ready to enter the AI era of Data Analysis?</span>

        return (
            <div>
                <div id="landingPageBrowser">
                    <PageBanner
                        title={title}
                        desc={desc}
                        img={Integration}
                    />
                    <div style={{ margin: "0 auto", width: "50%", paddingTop: "48px"}}>
                        <IntegrateBody/>
                    </div>
                    <div style={{ marginTop: "80px", paddingBottom: "200px"}}>
                        <div className="row">
                            <div className="col-sm-2" />
                            <div className="col-sm-8">
                                <div style={{ fontSize: "36px" }}>{ctaHeader}</div>
                                <div style={{ marginTop: "12px"}}>
                                    <input className="inputGray"
                                           style={inputStyle}
                                           value={this.state.animatedInput} readOnly={true} />
                                </div>
                                <div style={{ marginTop: "24px"}}>
                                    <RequestDemoSegment/>
                                </div>
                            </div>
                            <div className="col-sm-2" />
                        </div>
                    </div>
                </div>
                <div id="landingPageMobile">
                    <PageBanner
                        title={title}
                        desc={desc}
                        img={Integration}
                        isMobile
                    />
                    <div style={{ padding: "48px 20px 80px 20px"}}>
                        <IntegrateBody isMobile/>
                        <div style={{ marginTop: "48px"}}>
                            <div style={{ fontSize: "30px" }}>{ctaHeader}</div>
                        </div>
                        <div style={{ marginTop: "6px"}}>
                            <textarea className="inputGray"
                                      style={inputStyle}
                                      value={this.state.animatedInput}
                                      readOnly={true} />
                            </div>
                        <div style={{ marginTop: "12px"}}>
                            <RequestDemoSegment/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IntegrateIndex