import React, {Component} from 'react'
import FooterItem from "./FooterItem"

class ProductsLinks extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Products</div>
                <FooterItem to="https://www.apispreadsheets.com/" name="API Spreadsheets" external />
                <FooterItem to="https://www.mergespreadsheets.com/" name="Merge Spreadsheets" external />
                <FooterItem to="https://www.splitspreadsheets.com/" name="Split Spreadsheets" external />
                <FooterItem to="https://www.cleanspreadsheets.com/" name="Clean Spreadsheets" external />
            </div>
        )
    }
}

export default ProductsLinks