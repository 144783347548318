import React, {Component} from 'react'
import styles from "../Constants/styles";
import {images} from "../Constants/images";

class FooterIconAndTagline extends Component {
    render() {
        return (
            <div>
                <div style={{marginBottom: "24px"}}>
                    <img alt="Love Spreadsheets Logo" src={images.logoNav} style={{maxWidth: "240px"}} />
                </div>
                <div style={{fontFamily: "Lato", color: "#9696A0", fontWeight: "400", fontSize: "14px", marginBottom: "24px"}}>
                    <span>Extract data into <span style={{color: styles.red}}>spreadsheets</span> at light speed</span>
                </div>
            </div>
        )
    }
}

export default FooterIconAndTagline