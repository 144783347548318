import React from 'react'
import {scheduleCallText} from "../../Constants/scheduleCallText";

export const integrateFAQs =
    [
        {
            question: "What data sources can you integrate?",
            answer: "We can integrate any data source where the data can be accessed. The most common data sources that we integrate are databases, marketing platforms (via their API)"
        },
        {
            question: "Do you offer integration on my own servers?",
            answer: "Yes, we do! Our app can be installed and integrated on your servers via a Docker container super easily"
        },
        {
            question: "How long does it take for the integration?",
            answer: <div>It depends on whether you want it installed on your <b>own servers</b> or use <b>our web app</b>. <br/>For <b>our web app</b>, it takes 1-5 days to integrate your data sources depending on their complexity.<br/> For your <b>own servers</b>, it we can provide an estimate after discussing your requirements {scheduleCallText("on a call")}</div>
        },
        {
            question: "Can I integrate a data source by myself?",
            answer: <div>Yes! We provide an API for our chat bot that can be used to do the integration yourself. {scheduleCallText("Schedule a call")} with us and we can walk you through how to do it</div>
        },
        {
            question: "How much does integration with additional data sources cost?",
            answer: <div>It depends on these factors <ul><li>Do it yourself via our API vs. We build it for you</li><li>Use our web app vs. your own servers</li><li>Number of data source</li><li>Size of your data source</li><li>Frequency of interaction with the Chat Bot</li></ul>We can walk you through this in more detail {scheduleCallText("on a call")}</div>
        }
    ]

