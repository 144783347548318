import React, {Component} from 'react'
import API_Root from '../Constants/API_Root'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {toggleModal} from "../Actions/ToggleModal";
import BannerAlert from "../SharedComponents/BannerAlert";
import Button from "../SharedComponents/Button";
import {toggleIsAuth} from "../Actions/ToggleIsAuth";
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import GoogleSignIn from "./GoogleSignIn"
import {getModalInfoValue} from "../Constants/getModalInfoValue";
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {getTimezoneString} from "../Constants/getTimezoneString";

class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: this.initEmail(),
            passwordOne:'',
            passwordTwo:'',
            tandc: false,
            error: null,
            loading: false,
            plan: "0",
            orderName: getQueryParamValue("orderName", window.location.href),
            url: "/"
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.dismissMessage = this.dismissMessage.bind(this);
    }

    initEmail = () => {
        const url = window.location.href;

        if (url.includes("&email=")){
            return url.substring(url.indexOf("&email=") + "&email=".length, url.length).trim()
        } else { return '' }
    }

    login = (e) => {
        this.props.toggleModal(null)
        this.props.toggleModal("loginModal")
    }

    handleKeyPress(e){
        const isInvalid = this.state.passwordOne !== this.state.passwordTwo || this.state.passwordOne === '' || this.state.passwordOne.length < 8 || this.state.email === ''|| this.state.tandc === false;

        if (e.charCode === 13){
            if (!isInvalid){
                e.preventDefault()
                this.signUp(e)
            }
        }
    }

    dismissMessage(){
        this.setState({
            error: null
        })
    }

    changeState = (newState) => this.setState(newState)

    handleInputChange(e){
        if (e.target.name === "tandc"){
            this.setState({
                tandc: !this.state.tandc
            })
        }
        else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    signUp(e, emailP=null, passwordOneP=null, googleInfo=null){
        this.setState({
            loading: true
        })

        const email = emailP === null ? this.state.email : emailP;
        const passwordOne = passwordOneP === null ? this.state.passwordOne : passwordOneP;

        const API = API_Root + 'api/save-user/';

        const formData = {
            "email": email,
            "sessionId": localStorage.getItem("sessionId"),
            "username": email,
            "password": passwordOne,
            "source": localStorage.getItem("source"),
            "orderName": this.state.orderName,
            "googleInfo": googleInfo,
            "timezone": getTimezoneString()
        };

        const errorMessage = {message: <p>There was an error on our server creating the account. Please <a href={"mailto:info@lovespreadsheets.com?Subject=Create%20Account%20Error%20for%20" + email}>Email Us</a></p>};

        fetch(API, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then((res) => {
            if (res.status === 200){
                res.json().then((data) => {
                        localStorage.setItem("email", email.toLowerCase().trim());
                        localStorage.setItem("token", data.token);

                        this.setState({
                            loading: false
                        }, () => {
                            this.props.toggleIsAuth(true);

                            if (getModalInfoValue(this.props.modalInfo, 'nextLink') !== null){
                                this.setState({
                                    url: getModalInfoValue(this.props.modalInfo, 'nextLink')
                                }, () => this.nextSignupLink.click())
                            } else {
                                this.setState({
                                    url: "/"
                                }, () => this.nextSignupLink.click())
                                // this.props.navigateTo("/")
                            }

                            // this.props.toggleModal(null)
                        })
                    }
                ).catch(error => {
                    this.setState({
                        error: errorMessage ,
                        loading: false
                    });
                })
            }
            else if (res.status === 404){
                this.setState({
                    error: { message: <p>Account with email already exists. Please <span className="linkStyle" onClick={e => this.props.toggleModal("loginModal")}>login</span> or use a different email</p>},
                    loading: false
                })
            } else{
                this.setState({
                    error: errorMessage ,
                    loading: false
                })
            }
        }).catch(error => {
            this.setState({error:  errorMessage, loading: false});
        });
    }

    getFormHeader = () => {
        // let signUpMsg;
        // try{
        //     signUpMsg = this.props.modalInfo['signUpMsg']
        // } catch (e) {
        //     signUpMsg = null
        // }
        //
        // if (signUpMsg === null || signUpMsg === undefined){
        //     signUpMsg = "Sign Up"
        // }

        return getModalInfoValue(this.props.modalInfo, "signUpMsg", "Sign Up")
    }

    render() {
        const {
            email,
            passwordOne,
            passwordTwo,
            tandc,
            error
        } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || passwordOne.length < 8 || passwordOne.length > 40 || email === ''|| tandc === false;

        const passwordLengthError = passwordOne.length < 8 ? <div><small style={{color: 'red'}}>Password must be at least 8 characters</small></div> : null;
        const passwordMaxLengthError = passwordOne.length > 40 ? <div><small style={{color: 'red'}}>Password cannot be more than 40 characters</small></div> : null;
        const passwordMatchError = passwordOne !== passwordTwo ? <div><small style={{color: 'red'}}>Passwords Do Not Match</small></div> : null;

        let errorMessage = null;

        if (error !== null){
            if ("message" in error){
                errorMessage = <BannerAlert
                    icon='bx bx-error'
                    close={this.dismissMessage}
                    content=""
                    action="close"
                    header={error.message}
                    type="error"
                />
            }
        }

        return (
            <div style={{ background: "white", padding: "16px" }} onKeyPress={this.handleKeyPress}>
                <a href={this.state.url} ref={nextSignupLink => this.nextSignupLink = nextSignupLink} style={{ visibility: 'hidden' }}> </a>
                <div style={{ fontSize: "24px", fontWeight: "700", marginBottom: "12px"}}>
                    {this.getFormHeader()}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Enter your email
                </div>
                <input
                   id="signupEmail"
                   placeholder="Email" type="email" name="email"
                   onChange={this.handleInputChange}
                   value={email}
                   style={{width: "100%"}}
                   className="inputGray"
                />
                <br/><br/>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Enter a password (minimum 8 characters)
                </div>
                <input
                        id="signupPassword1"
                        placeholder="Password (at least 8 characters)" type="password" name="passwordOne"
                        onChange={this.handleInputChange}
                        value={passwordOne}
                        className="inputGray"
                        style={{width: "100%", border: passwordOne.length < 8 ? "1px solid red" : ""}}
                    />
                <br/><br/>
                <div style={{ fontSize: "16px", fontWeight: "700"}}>
                    Re-enter password
                </div>
                <input
                        id="signupPassword2"
                        placeholder="Confirm Password" type="password" name="passwordTwo"
                        onChange={this.handleInputChange}
                        value={passwordTwo}
                        className="inputGray"
                        style={{width: "100%", border: passwordOne.length < 8 ? "1px solid red" : ""}}
                />
                <br/>
                <input type="checkbox" name="tandc" id="tandc" onChange={this.handleInputChange} checked={tandc} />
                <label htmlFor="tandc">
                    <span style={{paddingLeft: '5px'}}>{"I agree with Love Spreadsheets'"}</span>
                    <a href="/terms" target="_blank"> Terms and Conditions</a>
                </label>
                <br/>
                {passwordLengthError}
                {passwordMaxLengthError}
                {passwordMatchError}
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button loading={this.state.loading}
                            id="signupButton"
                            disabled={isInvalid}
                            onClick={this.signUp}
                            label="Sign Up"
                            onKeyPress={this.handleKeyPress}
                            style={{width: "100%", backgroundColor: "green", color: "white"}}>
                    </Button>
                </div>
                <hr/>
                <GoogleSignIn signUp={this.signUp} changeState={this.changeState}/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    Already have an account? <span className="linkStyle" onClick={this.login} style={{ paddingLeft: "8px"}}>Log in</span>
                </div>
                {errorMessage}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    modalInfo: state.mainState.modalInfo
})

const mapActionsToProps = {
    navigateTo: push,
    toggleModal: toggleModal,
    toggleIsAuth: toggleIsAuth,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps,mapActionsToProps)(SignUpForm);
