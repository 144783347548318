import React, {Component} from 'react'
import FooterItem from "./FooterItem"

class CompanyLinks extends Component {
    render() {
        return (
            <div>
                <div style={this.props.headerStyle}>Company</div>
                <FooterItem to="integrate" name="Integrate other data" />
                <FooterItem to="https://lovespreadsheets.medium.com/" name="Blog" external/>
                <FooterItem to="careers" name="Careers" />
                <FooterItem to="contact" name="Contact Us" />
            </div>
        )
    }
}

export default CompanyLinks