export const supportHoursGMT = {
    // random monday
    startTime: "9/26/2022 11:00:00 AM UTC",
    // random friday
    endTime: "9/30/2022 11:00:00 PM UTC"
}

export const getSupportDays = () => ({
    startDay: getDayFromDate(supportHoursGMT.startTime),
    endDay: getDayFromDate(supportHoursGMT.endTime)
})

const getDayFromDate = (date) => {
    const localDate = new Date(date);

    return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][localDate.getDay()]
}

export const getSupportTimes = () => ({
    startTime: getTimeFromDate(supportHoursGMT.startTime),
    endTime: getTimeFromDate(supportHoursGMT.endTime)
})

const getTimeFromDate = (date) => {
    const localDate = new Date(date);
    const hours = localDate.getHours();

    const am_pm = hours >= 12 ? "PM" : "AM"

    return (hours > 12 ? hours - 12 : hours).toString() + am_pm
}