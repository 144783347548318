import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from "../Constants/styles";
import {toggleModal} from "../Actions/ToggleModal";
import UserInputArea from "../Apps/InputComponent/UserInputArea"
import HeroFlow from '../Illustrations/HeroFlow-01.svg'
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {calendlyLink} from "../Constants/calendlyLink";
import CustomerCarousel from "../SharedComponents/CustomerCarousel"
import LandingStep from "./LandingStep"
import ConnectDatabaseBody from "./LandingPageDescModal/ModalBody/ConnectDatabase/ConnectDatabaseBody";
import PromptDatabaseBody from "./LandingPageDescModal/ModalBody/PromptDatabaseBody";
import GetDataGoogleSheetsFrequency from "./GetDataGoogleSheetsFrequency";
import NoCodeTable from "./NoCodeTable/NoCodeTable"
import AceEditor from 'react-ace';
import 'brace/mode/sql';
import 'brace/theme/monokai';
import TeamHelpBody from "./LandingPageDescModal/TeamHelpBody";
import Person from "../Illustrations/Person.svg"
import CTARow from "./CTARow"
import SecurityRow from "./LandingPageDescModal/ModalBody/ConnectDatabase/SecurityRow";
import {images} from "../Constants/images";
import {hidePricing} from "../Constants/hidePricing";

class LandingPageBrowserIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: "SELECT \ncustomers.id,\ncustomers.email, \norders.item,\norders.price\nFROM\ncustomers, orders\nWHERE\ncustomers.id=orders.customer_id\nAND\ncustomers.date_joined >= '2022-10-01';\n"
        }
    }

    changeUserInput = (e) => this.setState({ code: e })

    isSourceMobile = () => ('isMobile' in this.props ? this.props.isMobile : false)

    render() {
        const segmentStyle = { backgroundColor: "white", padding: "12px", border: '1px solid #F0F0F0', borderRadius: "4px", boxShadow: "0px 5px 6px -4px rgba(2, 34, 19, 0.02)" };
        const isScreenMobile = this.isSourceMobile();
        const marginSection = isScreenMobile ? "80px 4px 0 4px" : "140px 100px 0 100px";

        return (
            <div>
                <div style={{ paddingTop: isScreenMobile ? "12px" : "48px"}}>
                    {/*<div style={{ fontWeight: "500", fontSize: "12px", textDecoration: "underline", textDecorationStyle: 'dotted', cursor: "pointer"}} onClick={e => this.props.toggleModal("languageModal")}>95+ languages supported</div>*/}
                    <div style={{ color: styles.mainText, fontSize: isScreenMobile ? "24px" : "36px", fontWeight: "700", textAlign: 'center'}}>
                        Automatically get data from databases & APIs <br/>into Google Sheets using natural language
                    </div>
                </div>
                <div style={{marginTop: "12px"}}>
                    <div style={isScreenMobile ? null : { width: '50%', margin: '0 auto'}}>
                        <UserInputArea demo isMobile={isScreenMobile}/>
                    </div>
                </div>
                <div style={{ textAlign: 'center'}}>
                    <img src={HeroFlow} alt="data going from files to visualization"
                         style={{ maxWidth: "240px", }}
                    />
                </div>
                <div style={{ marginTop: "24px"}}>
                    <CTARow isMobile={isScreenMobile}/>
                </div>
                <div style={{ padding: marginSection }}>
                    <div style={isScreenMobile ? null : {display: "grid", gridTemplateColumns: "auto 1fr", columnGap: "100px"}}>
                        <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", paddingTop: "20px"}}>
                            Trusted By
                        </div>
                        <div>
                            <CustomerCarousel isMobile={isScreenMobile}/>
                        </div>
                    </div>
                </div>
                <div style={{ margin: marginSection}}>
                    <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", textAlign: 'center'}}>
                        Get started in <span style={{ color: styles.red }}>3 easy steps</span>
                    </div>
                    <div style={{ marginTop: "80px"}}>
                        <LandingStep num={1}
                                     label="Connect your data sources"
                                     body={<ConnectDatabaseBody isMobile={isScreenMobile}/>}
                                     isMobile={isScreenMobile}
                        />
                    </div>
                    <div style={{ marginTop: isScreenMobile ? "80px": "120px"}}>
                        <LandingStep num={2}
                                     label="Type in natural language what you want"
                                     body={<PromptDatabaseBody isMobile={isScreenMobile}/>}
                                     isMobile={isScreenMobile}
                        />
                    </div>
                    <div style={{ marginTop: isScreenMobile ? "80px": "120px"}}>
                        <LandingStep num={3}
                                     label="Automatically get data in Google Sheets"
                                     body={<GetDataGoogleSheetsFrequency isMobile={isScreenMobile}/>}
                                     isMobile={isScreenMobile}
                        />
                    </div>
                </div>
                <div style={{ margin: marginSection}}>
                    <CTARow isMobile={isScreenMobile}/>
                </div>
                <div style={{ margin: marginSection}}>
                    <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", textAlign: 'center', marginBottom: "24px"}}>
                        What if I get the wrong data with natural language?
                    </div>
                    <div>
                        <div style={{ marginTop: "48px"}}>
                            <div style={{ margin: "0 auto", width: isScreenMobile ? "100%" : '50%'}}>
                                <div style={{fontSize: "24px", fontWeight: "700", textAlign: "center", color: styles.subText}}>
                                    Use no-code forms to get data
                                </div>
                                <div style={{ marginTop: "12px", textAlign: isScreenMobile ? "left": "auto"}}>
                                    <NoCodeTable isMobile={isScreenMobile}/>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "48px"}}>
                            <div style={{ margin: "0 auto", width: isScreenMobile ? "100%" : '50%'}}>
                                <div style={{ fontSize: "24px", fontWeight: "700", textAlign: "center", color: styles.subText}}>
                                    Or write code to get data
                                </div>
                                <div style={{ marginTop: "12px"}}>
                                    <AceEditor
                                        mode="sql"
                                        theme="monokai"
                                        onChange={this.changeUserInput}
                                        value={this.state.code}
                                        name="UNIQUE_ID_OF_DIV"
                                        editorProps={{$blockScrolling: true}}
                                        height={200}
                                        width="auto"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: marginSection}}>
                    <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", textAlign: 'center', marginBottom: "24px"}}>
                        What if I am not technical enough?
                    </div>
                    <div style={{ marginTop: "48px"}}>
                        <div style={{ fontSize: "24px", fontWeight: "700", textAlign: "center", color: styles.subText}}>
                            Create a team & invite team members to help
                        </div>
                        <div style={{ marginTop: "12px"}}>
                            <TeamHelpBody/>
                        </div>
                    </div>
                </div>
                {
                    hidePricing() ? null :
                        <div style={{ margin: marginSection}}>
                            <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", textAlign: 'center', marginBottom: "24px"}}>
                                What if I don't have a technical team?
                            </div>
                            <div style={{ marginTop: "48px", textAlign: "center",}}>
                                <div style={{ fontSize: "24px", fontWeight: "700", color: styles.subText}}>
                                    You can hire one of our excellent data specialist
                                </div>
                                <div style={{ marginTop: "24px"}}>
                                    <img src={Person} style={{ maxWidth: "120px"}} />
                                </div>
                                <div style={{ color: styles.subText, fontSize: "24px", fontWeight: "700", marginTop: "24px"}}>
                                    Starting at just <span style={{ color: styles.blue }}>$39/hour</span>
                                </div>
                            </div>
                        </div>
                }

                <div style={{ marginTop: "140px"}}>
                    <CTARow isMobile={isScreenMobile}/>
                </div>
                <div style={{ marginTop: "140px"}}>
                    <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", textAlign: 'center', marginBottom: "24px"}}>
                        Data Security
                    </div>
                    <div style={{ margin: isScreenMobile ? "0" : "0 100px 0 100px"}}>
                        <SecurityRow isMobile={isScreenMobile}/>
                    </div>
                </div>
                <div style={{ marginTop: "140px", marginBottom: "140px"}}>
                    <CTARow isMobile={isScreenMobile}/>
                </div>
                {
                    hidePricing() ? null :
                        <div style={{ marginTop: "140px", marginBottom: "200px", textAlign: 'center'}}>
                            <div style={{ color: styles.mainText, fontSize: "36px", fontWeight: "700", marginBottom: "24px"}}>
                                What if my data can't leave my servers?
                            </div>
                            <div style={{ fontSize: "18px", color: styles.subText}}>
                                We offer on-prem installations including Gen AI models!
                            </div>
                            <div style={{ marginTop: "12px"}}>
                                <div style={isScreenMobile ? null : {margin: "0 auto", width: "50%"}}>
                                    <div style={segmentStyle}>
                                        <div style={{ marginTop: "12px"}}>
                                            <a href="https://www.woyera.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                <img src={images.woyeraLogo} style={{ maxWidth: '240px'}}/>
                                            </a>
                                        </div>
                                        <div style={{ fontSize: "24px", color: styles.subText, marginTop: "24px"}}>
                                            <a href="https://www.woyera.com" target="_blank" rel="noopener noreferrer" className="linkStyle">Visit our service partner Woyera</a> {isScreenMobile ? null : <br/>}to learn more about this option
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyModalInfo: modifyModalInfo
}

export default connect(mapStateToProps, mapActionsToProps)(LandingPageBrowserIndex)